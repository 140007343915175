import axios from 'axios';

const contentServiceUrl = process.env.VUE_APP_CONTENT_SERVICE_URL;
const accessToken = localStorage.getItem('apiToken');

axios.defaults.withCredentials = false;
axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

export default {
  async getInfoHelpPage(stateAbbreviation) {
    return axios.get(`${contentServiceUrl}/content/cms/info-help/${stateAbbreviation}`).then((response) => {
      return response.data;
    });
  },
};