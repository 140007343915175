<template>
  <div v-html="text"></div>
</template>

<script>
import infoHelpService from "@/services/InfoHelpService";

export default {
  name: "InfoHelp",
  props: ['stateAbbreviation'],
  data () {
    return {
      isFetching: true,
      text: ''
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData: async function() {
      this.text = await infoHelpService.getInfoHelpPage(this.stateAbbreviation);
    }
  }
}
</script>

<style scoped>

</style>